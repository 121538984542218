import {
    TextField,
    TextFieldOnChangeArguments,
    TextFieldPropValue,
} from "@consta/uikit/TextField";
import {Button} from "@consta/uikit/Button";

import {useState} from "react";
import {auth} from "../../services/utils/api/auth";

import "./AuthForm.css";

function AuthPage() {
    const [value, setValue] = useState<string | null>(null);
    const handleChange = (value: TextFieldPropValue) => setValue(value);

    const submit = () => {
        const authRequest = {login: "buba", password: "bubs"};
        auth(authRequest, (response) => {
            alert(JSON.stringify(response));
        });
    };

    return (
        <div className="Auth-Page">
            <TextField
                onChange={handleChange}
                value={value}
                type="text"
                placeholder="Логин"
            />
            <TextField
                onChange={handleChange}
                value={value}
                type="password"
                placeholder="Пароль"
            />
            <Button label="Войти" onClick={submit} />
        </div>
    );
}
export default AuthPage;
