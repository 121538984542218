import {request} from "../request";

export type AuthRequest = {
    login: string;
    password: string;
};

export type AuthResponse = {
    token: string | undefined;
};

export function auth(
    creds: AuthRequest,
    onResult: (sphereResponse: AuthResponse) => void
) {
    let bodyString = JSON.stringify(creds);
    let config: RequestInit = {
        method: "post",
        body: bodyString,
    };
    request<AuthResponse>(process.env.REACT_APP_API_HOST + "/auth", config)
        .then((sphere) => {
            onResult(sphere);
        })
        .catch((e) => {
            console.log(e);
            onResult({token: undefined});
        });
}
