import "./App.css";
import AuthForm from "../authForm/AuthForm";
import {Button} from "@consta/uikit/Button";
import {useState} from "react";

function App() {
    const auth = () => {
        setAuthFormIsActive(true);
    };

    const [authFormIsActive, setAuthFormIsActive] = useState<boolean>(false);

    return (
        <div className="App">
            <div className="Page">
                {!authFormIsActive && <Button label="Войти" onClick={auth} />}
                {authFormIsActive && <AuthForm />}
            </div>
        </div>
    );
}

export default App;
